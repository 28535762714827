@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  width: 100%;
}

body {
  background-color: #f5f5f5;
  padding-top: 80px;
}

#root {
  width: 100%;
}